:root {
  --text-primary: #333333;
  --text-medium: #656565;
  --text-lighter: #979797;
  --text-lightest: #E7E7E7;
}
@font-face {
  font-family: inter;
  src: url(../public/fonts/interFont/Inter-Regular.ttf);
}
@font-face {
  font-family: inter;
  src: url(../public/fonts/interFont/Inter-Medium.ttf);
  font-weight: 500;
}
@font-face {
  font-family: inter;
  src: url(../public/fonts/interFont/Inter-SemiBold.ttf);
  font-weight: 600;
}
@font-face {
  font-family: inter;
  src: url(../public/fonts/interFont/Inter-Bold.ttf);
  font-weight: 700;
}
@font-face {
  font-family: inter;
  src: url(../public/fonts/interFont/Inter-ExtraBold.ttf);
  font-weight: 800;
}

@font-face {
  font-family: monoMedium;
  src: url(../public/fonts/Overpass_Mono/static/OverpassMono-Medium.ttf);
}
.math {
  font-family: inter;
  font-weight: 700;
  font-size: 1.25rem;
}
.table {
  position: relative;
  text-align: left;
  font-family: inter;
  font-weight: 600;
  font-size: 0.875rem;
}
.table table{
  border-spacing: 0px;
}
.table td {
  color: var(--text-lighter);
  line-height: 1.5rem;
}
.table tr td:last-child {
  color: var(--text-medium);
  padding-left: 2rem;
}
.table tr td:first-child {
  padding-left: 0rem;
}
.table th {
  font-weight: 700;
  color: var(--text-primary);
  border-bottom: 0.625rem solid rgba(0,0,0,0);
}
.table img {
  width: 1rem;
  height: 1rem;
  position: absolute;
  left: -2rem;
  top: 0.25rem;
}

.table a {
  text-decoration: none;
  color: var(--brand-primary-color);
}

.table a:visited{
  color: var(--brand-primary-color);
}

.react-international-phone-country-selector {
	position: relative;
}

.react-international-phone-country-selector-button {
	display: flex;
	height: 3.1875rem;
	box-sizing: border-box;
	align-items: center;
	justify-content: center;
	width: 7.25rem;
	margin: 0;
  padding: 1rem;
  background-color: var(--white-color);
  border: 0.0625rem solid var(--text-lightest);
  border-radius: 0.5rem;
  cursor: pointer;
}

.react-international-phone-country-selector-button__button-content {
	display: flex;
	align-items: center;
	justify-content: space-between;
  width: 100%;
  height: 100%;
}

.react-international-phone-country-selector-button__flag-emoji--disabled {
	opacity: .75;
}

.react-international-phone-country-selector-button__dropdown-arrow {
	border-top: var(--react-international-phone-country-selector-arrow-size, 4px) solid var(--react-international-phone-country-selector-arrow-color, #777);
	border-right: var(--react-international-phone-country-selector-arrow-size, 4px) solid transparent;
	border-left: var(--react-international-phone-country-selector-arrow-size, 4px) solid transparent;
	margin-right: 4px;
	transition: all .1s ease-out;
}

.react-international-phone-country-selector-button__dropdown-arrow--active {
	transform: rotateX(180deg);
}

.react-international-phone-country-selector-button__dropdown-arrow--disabled {
	border-top-color: var(--react-international-phone-disabled-country-selector-arrow-color, #999);
}

.react-international-phone-country-selector-button--disabled {
	background-color: var(--react-international-phone-disabled-country-selector-background-color, var(--react-international-phone-disabled-background-color, whitesmoke));
	cursor: auto;
}

.react-international-phone-country-selector-button--disabled:hover {
	background-color: var(--react-international-phone-disabled-country-selector-background-color, var(--react-international-phone-disabled-background-color, whitesmoke))
}

.react-international-phone-flag-emoji {
	width: var(--react-international-phone-flag-width, 24px);
	height: var(--react-international-phone-flag-height, 24px);
	box-sizing: border-box;
}

.react-international-phone-country-selector-dropdown {
	position: absolute;
	z-index: 1;
	top: var(--react-international-phone-dropdown-top, 44px);
	left: var(--react-international-phone-dropdown-left, 0);
	display: flex;
	width: 300px;
	max-height: 200px;
	flex-direction: column;
	padding: 4px 0;
	margin: 0;
	background-color: var(--react-international-phone-dropdown-item-background-color, var(--react-international-phone-background-color, white));
	box-shadow: var(--react-international-phone-dropdown-shadow, 2px 2px 16px rgba(0, 0, 0, .25));
	color: var(--react-international-phone-dropdown-item-text-color, var(--react-international-phone-text-color, #222));
	list-style: none;
	overflow-y: scroll;
}

.react-international-phone-country-selector-dropdown__preferred-list-divider {
	height: 1px;
	border: none;
	margin: var(--react-international-phone-dropdown-preferred-list-divider-margin, 0);
	background: var(--react-international-phone-dropdown-preferred-list-divider-color, var(--react-international-phone-border-color, gainsboro));
}

.react-international-phone-country-selector-dropdown__list-item {
	display: flex;
	min-height: var(--react-international-phone-dropdown-item-height, 28px);
	box-sizing: border-box;
	align-items: center;
	padding: 2px 8px;
}

.react-international-phone-country-selector-dropdown__list-item-flag-emoji {
	margin-right: 8px;
}

.react-international-phone-country-selector-dropdown__list-item-country-name {
	overflow: hidden;
	margin-right: 8px;
	font-size: var(--react-international-phone-dropdown-item-font-size, 14px);
	text-overflow: ellipsis;
	white-space: nowrap;
}

.react-international-phone-country-selector-dropdown__list-item-dial-code {
	color: var(--react-international-phone-dropdown-item-dial-code-color, gray);
	font-size: var(--react-international-phone-dropdown-item-font-size, 14px);
}

.react-international-phone-country-selector-dropdown__list-item:hover {
	background-color: var(--react-international-phone-selected-dropdown-item-background-color, var(--react-international-phone-selected-dropdown-item-background-color, whitesmoke));
	cursor: pointer;
}

.react-international-phone-country-selector-dropdown__list-item--selected,
.react-international-phone-country-selector-dropdown__list-item--focused {
	background-color: var(--react-international-phone-selected-dropdown-item-background-color, whitesmoke);
	color: var(--react-international-phone-selected-dropdown-item-text-color, var(--react-international-phone-text-color, #222));
}

.react-international-phone-country-selector-dropdown__list-item--selected .react-international-phone-country-selector-dropdown__list-item-dial-code,
.react-international-phone-country-selector-dropdown__list-item--focused .react-international-phone-country-selector-dropdown__list-item-dial-code {
	color: var(--react-international-phone-selected-dropdown-item-dial-code-color, var(--react-international-phone-dropdown-item-dial-code-color, gray));
}

.react-international-phone-country-selector-dropdown__list-item--focused {
	background-color: var(--react-international-phone-selected-dropdown-item-background-color, var(--react-international-phone-selected-dropdown-item-background-color, whitesmoke));
}
